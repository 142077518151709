$xs-device : 600px; // max-width
$sm-device : 600px; // min-width
$md-device : 768px; // min-width
$lg-device : 992px; // min-width
$xlg-device : 1200px; // min-width

$primary-color: #ca052e;
$secondary-color: #00256f;
$white-color: #ffffff;

.development-main {
    margin-bottom: 7%;

    @media screen and (max-width: $sm-device) {
        margin-bottom: 0;
    }

    .work-heading {
        text-align: center;
        font-size: 30px;
        color: $primary-color;
        font-weight: 500;
        padding-bottom: 5%;

        @media screen and (max-width: $xs-device) {
            font-size: 22px;
        }
    }

    /* CARDS STARTING */
    .card {
        width: 80%;
        height: 260px;
        border-radius: 15px;
        padding: 1.5rem;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        -webkit-transition: 0.4s ease-out;
        transition: 0.4s ease-out;
        box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
    }

    .card:hover {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }

    .card:hover:before {
        opacity: 1;
    }

    .card:hover .info {
        opacity: 1;
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }

    .card:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 15px;
        background: rgba(0, 0, 0, 0.6);
        z-index: 2;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        opacity: 0;
    }

    .card img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 15px;
    }

    .card .info {
        position: relative;
        z-index: 3;
        color: white;
        opacity: 0;
        -webkit-transform: translateY(30px);
        transform: translateY(30px);
        -webkit-transition: 0.5s;
        transition: 0.5s;
    }

    .card .info h2 {
        margin: 0px;
    }

    .card .info button {
        padding: 0.6rem;
        outline: none;
        border: none;
        border-radius: 3px;
        background: white;
        color: black;
        font-weight: bold;
        cursor: pointer;
        -webkit-transition: 0.4s ease;
        transition: 0.4s ease;
        margin-top: 12%;
    }

    .card .info button:hover {
        background: #51bcda;
        color: white;
    }

    .timeline-heading {
        text-align: center;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        padding-top: 10%;
        padding-bottom: 5%;

        .our {
            font-size: 34px;
            color: $primary-color;
            font-weight: 700;
        }

        .methodology {
            font-size: 34px;
            color: $secondary-color;
            font-weight: 500;
        }
    }
    /*CARDS ENDING */

    .pad-top {
        @media screen and (min-width: $xs-device) {
            margin-top: 7%;
        }
    }

    .staffing-heading {
        background-color: $white-color;
        margin-top: 5%;
    }

    .staffing-work-heading {
        text-align: center;
        font-size: 30px;
        color: $primary-color;
        font-weight: 500;
        padding-top: 3%;
        padding-bottom: 3%;

        @media screen and (max-width: $xs-device) {
            font-size: 26px;
        }
    }

    .count-content-one {
        padding: 1% 2% 2% 5%;

        @media screen and (max-width: $sm-device) {
            display: none;
        }

        p {
            padding-top: 10px;
        }
    }

    .count-content-two {
        padding: 1% 2% 2% 5%;

        @media screen and (min-width: $sm-device) {
            display: none;
        }

        p {
            padding-top: 10px;
        }
    }

}






/* .main-flex {
    padding-bottom: 4%;
    display: flex;
    text-align: left;

    .first-flex {  
        float: left;
        width: 25%;
        padding: 3% 2% 2% 5%;

        @media screen and (max-width: $xs-device) {
            width: 100%;
        }
    }
    
    .second-flex {
        float: left;
        width: 25%;
        padding: 3% 2% 2% 5%;

        @media screen and (max-width: $xs-device) {
            width: 100%;
        }
    }

    .third-flex {
        float: left;
        width: 25%;
        padding: 3% 2% 2% 5%;

        @media screen and (max-width: $xs-device) {
            width: 100%;
        }
    }

    .fourth-flex {
        float: left;
        width: 25%;
        padding: 3% 2% 2% 5%;

        @media screen and (max-width: $xs-device) {
            width: 100%;
        }
    }
} */




  












/*   .dev-first-card {
    height: 45vh;
    width: 80%;
    background-image: url("../../assets/img/customImages/compressed.jpg");
    margin-left: auto;
    margin-right: auto;
    transition: all 0.3s ease;
    transform: scale(1);
    background-size: cover;
    background-repeat: no-repeat;
}

.dev-first-card:hover {
    transform: scale(1.1) perspective(1px)
}

.dev-second-card {
    height: 45vh;
    width: 80%;
    background-image: url("../../assets/img/customImages/compressed.jpg");
    margin-left: auto;
    margin-right: auto;
    transition: all 0.3s ease;
    transform: scale(1);
    background-size: cover;
    background-repeat: no-repeat;
}

.dev-second-card:hover {
    transform: scale(1.1) perspective(1px)
}

.dev-third-card {
    height: 45vh;
    width: 80%;
    background-image: url("../../assets/img/customImages/compressed.jpg");
    margin-left: auto;
    margin-right: auto;
    transition: all 0.3s ease;
    transform: scale(1);
    background-size: cover;
    background-repeat: no-repeat;
}

.dev-third-card:hover {
    transform: scale(1.1) perspective(1px)
}

.dev-fourth-card {
    height: 45vh;
    width: 80%;
    background-image: url("../../assets/img/customImages/compressed.jpg");
    margin-left: auto;
    margin-right: auto;
    transition: all 0.3s ease;
    transform: scale(1);
    background-size: cover;
    background-repeat: no-repeat;
}

.dev-fourth-card:hover {
    transform: scale(1.1) perspective(1px)
} */