$xs-device: 600px; // max-width
$sm-device: 600px; // min-width
$md-device: 768px; // min-width
$lg-device: 992px; // min-width
$xlg-device: 1200px; // min-width

.contact-main {
    width: 100%;
    height: 100%;
    margin-bottom: 5%;

    .heading-content {
        text-align: center;
        padding-top: 5%;
        padding-bottom: 6%;

        @media screen and (max-width: $xs-device) {
            padding-top: 30%;
        }

        @media screen and (min-width: $sm-device) and (max-width: $md-device) {
            padding-top: 25%;
        }

        @media screen and (min-width: $md-device) and (max-width: $lg-device) {
            padding-top: 20%;
        }

        @media screen and (min-width: $lg-device) and (max-width: $xlg-device) {
            padding-top: 15%;
        }

        span {
            font-size: 2em;

            @media screen and (max-width: $xs-device) {
                font-size: 1.5em;
            }
        }
    }

    input {
        width: 90%;
        padding: 10px 0 10px 0;
        border: 1px solid rgba(111, 0, 255, 0.315);
        border-radius: 8px;
        background: transparent;
        display: list-item;
        outline: none;
        text-indent: 17px;
        color: black;
        font-weight: 400;

        @media screen and (max-width: 600px) {
            width: 100%;
        }
    }

    select {
        width: 90%;
        padding: 10px 0 10px 0;
        border: 1px solid rgba(111, 0, 255, 0.315);
        border-radius: 8px;
        background: transparent;
        display: list-item;
        outline: none;
        text-indent: 17px;
        color: black;
        font-weight: 400;

        @media screen and (max-width: 600px) {
            width: 100%;
        }
    }

    textarea {
        width: 90%;
        padding: 10px 0 10px 0;
        border: 1px solid rgba(111, 0, 255, 0.315);
        border-radius: 8px;
        background: transparent;
        display: list-item;
        outline: none;
        height: 100px;
        text-indent: 17px;
        color: black;
        font-weight: 400;

        @media screen and (max-width: 600px) {
            width: 100%;
        }
    }

    button {
        outline: none;
        border: 1px solid rgba(111, 0, 255, 0.315);
        border-radius: 8px;
        width: 90%;
        padding: 8px 50px 8px 50px;
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        background-color: transparent;

        @media screen and (max-width: 600px) {
            width: 100%;
        }
    }

    button:hover {
        background-color: #2baacd8e;
        color: #fff;
    }

    ::placeholder {
        color: black;
        font-weight: 400;
        font-size: 14px;
    }

    .contact-strategy {
        @media screen and (max-width: $md-device) {
            padding-top: 10%;
        }

        .image-align {
            text-align: center;
        }

        .content-heading {
            font-weight: 700;
            padding-top: 10px;
            font-size: 12px;
            text-align: center;
        }

        .content-sub-heading {
            font-weight: 500;
            padding-top: 5px;
            font-size: 15px;
            color: #ca052e;
            text-align: center;
        }

        .content-para {
            font-weight: 500;
            padding: 5px;
            font-size: 12px;
            text-align: justify;
        }
    }
}

.validationMsg {
    font-size: 12px;
    color: red;
    font-weight: 500;
    padding-top: 7px;
}


.locations-main {
    padding: 5% 0 0 0;
}

.locations-ipad-mobile {
    padding: 5% 0 0 0;
}

.locations-main-pad {
    text-align: center;
    padding: 0 0 5% 0;
}

.location-main-heading {
    font-weight: 500;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

@media screen and (min-width: $xlg-device) {
    .locations-main {
        display: initial;
    }
}

@media screen and (max-width: $xlg-device) {
    .locations-main {
        display: none;
    }
}

@media screen and (min-width: $xlg-device) {
    .locations-ipad-mobile {
        display: none;
    }
}

/* .our-locations-images {
    position: relative;
    padding-top: 5%;

    .first-location {
        clip-path: polygon(100% 0, 0 100%, 100% 100%, 75% 100%, 0 100%, 0 0, 53% 0);
        height: 350px;
        width: 100%;
        background-image: url("../../assets/img/customImages/USAONE.png");
        background-position: left top;
        position: absolute;

        @media screen and (max-width: 600px) {
            background-position: right;
        }

        .first-text {
            color: black;
            font-size: 40px;
            position: absolute;
            top: 20%;
            left: 10%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            text-align: center;
            font-weight: 500;

            @media screen and (min-width: $md-device) and (max-width: $lg-device) {
                font-size: 30px;
            }

            @media screen and (max-width: 600px) {
                font-size: 30px;
                left: 20%;
                top: 20%;
            }
        }
    }

    .first-location:hover {
        opacity: 0.8;
    }

    .second-location {
        clip-path: polygon(100% 0, 100% 100%, 75% 100%, 0 100%, 0 100%);
        height: 350px;
        width: 100%;
        background-image: url("../../assets/img/customImages/INDIAONE.png");
        background-position: left top;

        @media screen and (min-width: $md-device) and (max-width: $lg-device) {
            background-position: center top;
        }

        @media screen and (max-width: 600px) {
            background-position: right;
            opacity: 0.7;
        }

        .second-text {
            color: black;
            font-size: 40px;
            position: absolute;
            top: 80%;
            left: 90%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            text-align: center;
            font-weight: 500;

            @media screen and (min-width: $md-device) and (max-width: $lg-device) {
                font-size: 30px;
            }

            @media screen and (max-width: 600px) {
                font-size: 30px;
                left: 70%;
                top: 80%;
            }
        }
    }

    .second-location:hover {
        opacity: 0.7;
    }

} */
