$xs-device : 600px; // max-width
$sm-device : 600px; // min-width
$md-device : 768px; // min-width
$lg-device : 992px; // min-width
$xlg-device : 1200px; // min-width

$primary-color: #ca052e;
$secondary-color: #00256f;
$white-color: #ffffff;

.staffing-main {
    margin-top: 5%;
    margin-bottom: 5%;

    p:nth-child(1) {
        text-align: justify;
        font-weight: 500;

        @media screen and (max-width: $xs-device) {
            padding: 10px 10px 0 10px;
        }
    }
    
    p:nth-child(2) {
        text-align: justify;
        padding-top: 3%;
        font-weight: 500;

        @media screen and (max-width: $xs-device) {
            padding: 10px 10px 0 10px;
        }
    }

    .main-staffing-models-content {
        padding-top: 5%;
        padding-bottom: 5%;

        .staffing-heading {
            text-align: center;
            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

            .our {
                font-size: 34px;
                color: $primary-color;
                font-weight: 700;

                @media screen and (max-width: $xs-device) {
                    font-size: 28px;
                }
            }

            .staffing-models {
                font-size: 34px;
                color: $secondary-color;
                font-weight: 500;

                @media screen and (max-width: $xs-device) {
                    font-size: 28px;
                }
            }
        }


        .main-container {
            position: relative;
            width: 90%;
            margin-left: auto;
            margin-right: auto;
        }
            
        .image {
            display: block;
            width: 100%;
            height: auto;
            opacity: 0.5;
        }

        .intial-text {
            color: $secondary-color;
            font-size: 23px;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            text-align: center;
            font-weight: 500;
        }
            
        .overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            transition: .5s ease;
            background-color: #008CBA;
        }
            
        .main-container:hover .overlay {
            opacity: 1;
        }
            
        .hover-text {
            color: white;
            font-size: 18px;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            text-align: center;
            text-transform: capitalize;

            @media screen and (max-width: $xs-device) {
                font-size: 14px;
            }
        }

        .common-padding {
           padding-top: 6%; 

            @media screen and (max-width: $xs-device) {
                padding-top: 0;
            }
        }

        .column-padding {
            @media screen and (max-width: $xs-device) {
                padding-top: 10%;
            }
        }

    }
}









/* .staffing-image {
    height: 100%;
    width: 90%;
    background-image: url("../../assets/img/customImages/staffing2.jpg");
    margin-left: auto;
    margin-right: auto;
    background-size: cover;
    background-repeat: no-repeat;
}

p:nth-child(1) {
    text-align: justify;
    font-weight: 500;
}

p:nth-child(2) {
    text-align: justify;
    padding-top: 3%;
    font-weight: 500;
} */