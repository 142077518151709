$xs-device : 600px; // max-width
$sm-device : 600px; // min-width
$md-device : 768px; // min-width
$lg-device : 992px; // min-width
$xlg-device : 1200px; // min-width

$primary-color: #ca052e;
$secondary-color: #00256f;
$white-color: #ffffff;

body { 
    background-color: #F7F2F0;
}

.main-home-page-video {
    @media screen and (min-width: $xlg-device) {
        display: initial;
    }

    @media screen and (max-width: $xlg-device) {
        display: none;
    }
} 

.ipad-home-page-video {

    @media screen and (max-width: $sm-device) {
        display: none;
    }

    @media screen and (min-width: $xlg-device) {
        display: none;
    }
}

.phone-home-page-video {

    @media screen and (min-width: $md-device) {
        display: none;
    }
}



.main-home-page-content {
    padding-top: 5%;
   
    .main-heading {
        text-align: left;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

        @media screen and (max-width: $xs-device) {
            text-align: center;
        }

        .who-title {
            font-size: 34px;
            color: $primary-color;
            font-weight: 700;

            @media screen and (max-width: $xs-device) {
                font-size: 26px;
            }
        }

        .we-are-title {
            font-size: 34px;
            color: $secondary-color;
            font-weight: 500;

            @media screen and (max-width: $xs-device) {
                font-size: 26px;
            }
        }
    }

    .who-we-are-heading-content {
        text-align: left;
        font-weight: 500;
        color: #5E4F45;
        font-size: 15px;

        @media screen and (max-width: $xs-device) {
            text-align: justify;
            padding: 10px 10px 0 10px;
            font-size: 13px;
        }
    }

    .description-content {
        font-weight: 500;
        
        @media screen and (max-width: $xs-device) {
            text-align: justify;
            padding: 10px 10px 0 10px;
        }
    }

    .button-one {
        @media screen and (max-width: $xs-device) {
            padding: 10px 10px 0 10px;
        }
    }

    .button-two {
        @media screen and (max-width: $xs-device) {
            padding: 10px 10px 0 10px;
        }
    }

    .image-background {
        img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 70%;

            @media screen and (min-width: $md-device) and (max-width: $lg-device) {
                width: 90%;
            }
        }

        @media screen and (max-width: $xs-device) {
            display: none;
        }
    }

    .main-box {
        margin-top: 5%;

        .box1 {
            height: 100%;
            width: 100%;
            background-image: url("../../assets/img/compressedImages/our-work.jpg");
            background-size: cover;
            display: table;
            background-attachment: scroll;
            

            .box1-content {
                margin: 0px;
                display: table-cell;
                background: rgba(102, 97, 91, 0.582);
                text-align: center;
                padding-top: 4%;
            }

            .latest-work {
                padding-bottom: 50px;
                font-size: 35px;
                color: $white-color;

                @media screen and (max-width: $xs-device) {
                    padding-bottom: 30px;
                    padding-top: 20px;
                }
            }

            .first-left-content {
                float:left;
                width: 70%; 
                text-align: justify;
                padding: 0 25px 25px 25px;
                background-color: rgba(7, 6, 6, 0.582);

                @media screen and (max-width: $xs-device) {
                    width: 100%; 
                }

                @media screen and (min-width: $md-device) and (max-width: $lg-device) {
                    width: 75%;
                }

                @media screen and (min-width: $lg-device) and (max-width: $xlg-device) {
                    width: 75%;
                }

                .heading-style {
                    font-size: 25px;
                }

                h4 {
                    text-align: left;
                    color: $white-color;
                    padding-bottom: 15px;
                    font-weight: 300;
                }

                p {
                    font-size: 16px;   
                    color: #F7F2F0;

                    @media screen and (max-width: $xs-device) {
                        font-size: 15px; 
                    }
                }
            }

            .first-right-content {
                float:left;
                width: 30%; 
                height:45vh;
                
                @media screen and (max-width: $xs-device) {
                    display: none;
                }

                @media screen and (min-width: $md-device) and (max-width: $lg-device) {
                    height:35vh;
                    width: 25%;
                }

                @media screen and (min-width: $lg-device) and (max-width: $xlg-device) {
                    height:25vh;
                    width: 25%;
                }
            }

            .second-left-content {
                float:left;
                width: 30%; 
                height:45vh;

                @media screen and (max-width: $xs-device) {
                    display: none;
                }

                @media screen and (min-width: $md-device) and (max-width: $lg-device) {
                    height:35vh;
                    width: 25%;
                }

                @media screen and (min-width: $lg-device) and (max-width: $xlg-device) {
                    height:25vh;
                    width: 25%;
                }
            }

            .second-right-content {
                float:left;
                width: 70%; 
                padding: 0 25px 25px 25px;
                text-align: justify;
                background-color: rgba(7, 6, 6, 0.582);

                @media screen and (max-width: $xs-device) {
                    width: 100%; 
                }

                @media screen and (min-width: $md-device) and (max-width: $lg-device) {
                    width: 75%;
                }

                @media screen and (min-width: $lg-device) and (max-width: $xlg-device) {
                    width: 75%;
                }

                .heading-style {
                    font-size: 25px;
                }

                h4 {
                    text-align: left;
                    color: $white-color;
                    padding-bottom: 15px;
                    font-weight: 300;
                }

                p {
                    font-size: 16px;
                    color: #F7F2F0;
                }
            }
        }
    }

    .our-main-presence {
        text-align: center;
        margin-top: 5%;
        margin-bottom: 6%;
        overflow: hidden;
    
        .our-title {
            font-size: 34px;
            color: $primary-color;
            font-weight: 700;
            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

            @media screen and (max-width: $xs-device) {
                font-size: 26px;
            }
        }
    
        .presence-title {
            font-size: 34px;
            color: $secondary-color;
            font-weight: 500;
            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

            @media screen and (max-width: $xs-device) {
                font-size: 26px;
            }
        }

        .our-presence-content {
            font-weight: 500;
            font-size: 16px;
            text-align: center;
            padding-top: 3%;

            @media screen and (max-width: $xs-device) {
                text-align: justify;
                padding: 8px 8px 0 8px;
            }
        }

    }

    .slider-background {
        /* background-image: url("../../assets/img/customImages/bgtwo.jpg");
        text-align: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-attachment: fixed; */

        text-align: center;
        background-color: $white-color;
    
        .heading-pad {
            padding-top: 4%;
            padding-bottom: 3%;

            @media screen and (max-width: $xs-device) {
                padding-bottom: 4%;
                padding-top: 5%;
            }

            @media screen and (min-width: $md-device) and (max-width: $lg-device) {
                padding-bottom: 1%;
            }

            @media screen and (min-width: $lg-device) and (max-width: $xlg-device) {
                padding-bottom: 1%;
            }
    
            .our-title {
                font-size: 34px;
                color: $primary-color;
                font-weight: 700;
                font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

                @media screen and (max-width: $xs-device) {
                    font-size: 26px;
                }
            }
    
            .clients-title {
                font-size: 34px;
                color: $secondary-color;
                font-weight: 500;
                font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

                @media screen and (max-width: $xs-device) {
                    font-size: 26px;
                }
            }
        }
    
        .slider-pad {
            padding-bottom: 5%;

            
            @media screen and (min-width: $md-device) and (max-width: $lg-device) {
                padding-bottom: 1%;
            }

            @media screen and (min-width: $lg-device) and (max-width: $xlg-device) {
                padding-bottom: 1%;
            }
        }
    }

    .image-one {
        background-image: url("../../assets/img/partnersImages/accenture.PNG");
        width: 100%; 
        height: 20vh;
        background-size: 150px 40px;
        background-repeat: no-repeat;
        background-position: center;

        @media screen and (max-width: $xs-device) {
            background-size: 100px 30px;
            height: 10vh;
        }

        @media screen and (min-width: $md-device) and (max-width: $lg-device) {
            background-size: 120px 35px;
            height: 15vh;
        }
    }

    .image-two {
        background-image: url("../../assets/img/partnersImages/birlasoft.svg");
        width: 100%; 
        height: 20vh;
        background-size: 150px 60px;
        background-repeat: no-repeat;
        background-position: center;

        @media screen and (max-width: $xs-device) {
            background-size: 100px 40px;
            height: 10vh;
        }

        @media screen and (min-width: $md-device) and (max-width: $lg-device) {
            background-size: 140px 40px;
            height: 15vh;
        }
    }

    .image-three {
        background-image: url("../../assets/img/partnersImages/brillio.png");
        width: 100%; 
        height: 20vh;
        background-size: 150px 45px;
        background-repeat: no-repeat;
        background-position: center;

        @media screen and (max-width: $xs-device) {
            background-size: 100px 40px;
            height: 10vh;
        }

        @media screen and (min-width: $md-device) and (max-width: $lg-device) {
            background-size: 140px 40px;
            height: 15vh;
        }
    }

    .image-four {
        background-image: url("../../assets/img/partnersImages/citius.png");
        width: 100%; 
        height: 20vh;
        background-size: 170px 40px;
        background-repeat: no-repeat;
        background-position: center;

        @media screen and (max-width: $xs-device) {
            background-size: 100px 25px;
            height: 10vh;
        }

        @media screen and (min-width: $md-device) and (max-width: $lg-device) {
            background-size: 140px 35px;
            height: 15vh;
        }
    }

    .image-five {
        background-image: url("../../assets/img/partnersImages/deloitte.png");
        width: 100%; 
        height: 20vh;
        background-size: 200px 80px;
        background-repeat: no-repeat;
        background-position: center;

        @media screen and (max-width: $xs-device) {
            background-size: 100px 50px;
            height: 10vh;
        }

        @media screen and (min-width: $md-device) and (max-width: $lg-device) {
            background-size: 140px 70px;
            height: 15vh;
        }
    }

    .image-six {
        background-image: url("../../assets/img/partnersImages/Galaxe.svg");
        width: 100%; 
        height: 20vh;
        background-size: 160px 55px;
        background-repeat: no-repeat;
        background-position: center;

        @media screen and (max-width: $xs-device) {
            background-size: 100px 40px;
            height: 10vh;
        }

        @media screen and (min-width: $md-device) and (max-width: $lg-device) {
            background-size: 120px 60px;
            height: 15vh;
        }
    }

    .image-seven {
        background-image: url("../../assets/img/partnersImages/insight.png");
        width: 100%; 
        height: 20vh;
        background-size: 150px 35px;
        background-repeat: no-repeat;
        background-position: center;

        @media screen and (max-width: $xs-device) {
            background-size: 100px 25px;
            height: 10vh;
        }

        @media screen and (min-width: $md-device) and (max-width: $lg-device) {
            background-size: 120px 35px;
            height: 15vh;
        }
    }

    .image-eight {
        background-image: url("../../assets/img/partnersImages/itc.png");
        width: 100%; 
        height: 20vh;
        background-size: 150px 45px;
        background-repeat: no-repeat;
        background-position: center;

        @media screen and (max-width: $xs-device) {
            background-size: 100px 60px;
            height: 10vh;
        }

        @media screen and (min-width: $md-device) and (max-width: $lg-device) {
            background-size: 120px 35px;
            height: 15vh;
        }
    }

    .image-nine {
        background-image: url("../../assets/img/partnersImages/lnt.png");
        width: 100%; 
        height: 20vh;
        background-size: 130px 40px;
        background-repeat: no-repeat;
        background-position: center;

        @media screen and (max-width: $xs-device) {
            background-size: 100px 30px;
            height: 10vh;
        }

        @media screen and (min-width: $md-device) and (max-width: $lg-device) {
            background-size: 70px 30px;
            height: 15vh;
        }
    }

    .image-ten {
        background-image: url("../../assets/img/partnersImages/nttData.png");
        width: 100%; 
        height: 20vh;
        background-size: 150px 35px;
        background-repeat: no-repeat;
        background-position: center;

        @media screen and (max-width: $xs-device) {
            background-size: 100px 30px;
            height: 10vh;
        }

        @media screen and (min-width: $md-device) and (max-width: $lg-device) {
            background-size: 120px 35px;
            height: 15vh;
        }
    }

    .image-eleven {
        background-image: url("../../assets/img/partnersImages/randstad.png");
        width: 100%; 
        height: 20vh;
        background-size: 150px 60px;
        background-repeat: no-repeat;
        background-position: center;

        @media screen and (max-width: $xs-device) {
            background-size: 100px 40px;
            height: 10vh;
        }

        @media screen and (min-width: $md-device) and (max-width: $lg-device) {
            background-size: 120px 35px;
            height: 15vh;
        }
    }

    .image-twelve {
        background-image: url("../../assets/img/partnersImages/syapps.png");
        width: 100%; 
        height: 20vh;
        background-size: 150px 45px;
        background-repeat: no-repeat;
        background-position: center;

        @media screen and (max-width: $xs-device) {
            background-size: 100px 40px;
            height: 10vh;
        }

        @media screen and (min-width: $md-device) and (max-width: $lg-device) {
            background-size: 120px 35px;
            height: 15vh;
        }
    }

    .image-thirteen {
        background-image: url("../../assets/img/partnersImages/tata.png");
        width: 100%; 
        height: 20vh;
        background-size: 160px 45px;
        background-repeat: no-repeat;
        background-position: center;

        @media screen and (max-width: $xs-device) {
            background-size: 100px 40px;
            height: 10vh;
        }

        @media screen and (min-width: $md-device) and (max-width: $lg-device) {
            background-size: 120px 35px;
            height: 15vh;
        }
    }

    .image-fourteen {
        background-image: url("../../assets/img/partnersImages/Tavant.png");
        width: 100%; 
        height: 20vh;
        background-size: 150px 45px;
        background-repeat: no-repeat;
        background-position: center;

        @media screen and (max-width: $xs-device) {
            background-size: 100px 30px;
            height: 10vh;
        }

        @media screen and (min-width: $md-device) and (max-width: $lg-device) {
            background-size: 120px 35px;
            height: 15vh;
        }
    }

    .image-fifteen {
        background-image: url("../../assets/img/partnersImages/TCS.png");
        width: 100%; 
        height: 20vh;
        background-size: 130px 50px;
        background-repeat: no-repeat;
        background-position: center;

        @media screen and (max-width: $xs-device) {
            background-size: 100px 40px;
            height: 10vh;
        }

        @media screen and (min-width: $md-device) and (max-width: $lg-device) {
            background-size: 120px 45px;
            height: 15vh;
        }
    }

    .image-sixteen {
        background-image: url("../../assets/img/partnersImages/tek.png");
        width: 100%; 
        height: 20vh;
        background-size: 150px 45px;
        background-repeat: no-repeat;
        background-position: center;

        @media screen and (max-width: $xs-device) {
            background-size: 100px 40px;
            height: 10vh;
        }

        @media screen and (min-width: $md-device) and (max-width: $lg-device) {
            background-size: 120px 40px;
            height: 15vh;
        }
    }

    .image-seventeen {
        background-image: url("../../assets/img/partnersImages/ust.png");
        width: 100%; 
        height: 20vh;
        background-size: 150px 45px;
        background-repeat: no-repeat;
        background-position: center;

        @media screen and (max-width: $xs-device) {
            background-size: 100px 40px;
            height: 10vh;
        }

        @media screen and (min-width: $md-device) and (max-width: $lg-device) {
            background-size: 120px 35px;
            height: 15vh;
        }
    }

    .image-eighteen {
        background-image: url("../../assets/img/partnersImages/wipro.png");
        width: 100%; 
        height: 20vh;
        background-size: 100px 60px;
        background-repeat: no-repeat;
        background-position: center;

        @media screen and (max-width: $xs-device) {
            background-size: 100px 40px;
            height: 10vh;
        }

        @media screen and (min-width: $md-device) and (max-width: $lg-device) {
            background-size: 120px 50px;
            height: 15vh;
        }
    }

    
    .image-nineteen {
        background-image: url("../../assets/img/partnersImages/wise.png");
        width: 100%; 
        height: 20vh;
        background-size: 150px 45px;
        background-repeat: no-repeat;
        background-position: center;

        @media screen and (max-width: $xs-device) {
            background-size: 100px 40px;
            height: 10vh;
        }

        @media screen and (min-width: $md-device) and (max-width: $lg-device) {
            background-size: 120px 35px;
            height: 15vh;
        }
    }

    
    .image-twenty {
        background-image: url("../../assets/img/partnersImages/workforceLogiq.png");
        width: 100%; 
        height: 20vh;
        background-size: 150px 40px;
        background-repeat: no-repeat;
        background-position: center;

        @media screen and (max-width: $xs-device) {
            background-size: 100px 40px;
            height: 10vh;
        }

        @media screen and (min-width: $md-device) and (max-width: $lg-device) {
            background-size: 120px 35px;
            height: 15vh;
        }
    }

    .image-twenty-one {
        background-image: url("../../assets/img/customImages/xmplar.png");
        width: 100%; 
        height: 20vh;
        background-size: 150px 40px;
        background-repeat: no-repeat;
        background-position: center;

        @media screen and (max-width: $xs-device) {
            background-size: 100px 40px;
            height: 10vh;
        }

        @media screen and (min-width: $md-device) and (max-width: $lg-device) {
            background-size: 120px 35px;
            height: 15vh;
        }
    }
}

.mobile-pics {
    @media screen and (max-width: $xs-device) {
        padding: 10px 10px 10px 10px;
    }

    @media screen and (min-width: $xs-device) {
        display: none;
    }
}









/* .who-title {
    color: $primary-color;
    font-weight: 501;
  
    @media screen and (max-width: $xs-device) {
        font-size: 30px;
    }
}
  
.we-are-title {
    color: $secondary-color;
    
    @media screen and (max-width: $xs-device) {
        font-size: 29px;
    }
}
  
.we-are-white-title {
    color: $white-color;
  
    @media screen and (max-width: $xs-device) {
        font-size: 29px;
    }
}
  
.who-we-are-heading-content {
    font-size: 21px;
    font-weight: 500;
  
    @media screen and (max-width: $xs-device) {
        font-size: 15px;
    }
}
  
.description-content {
    font-weight: 400;
    
    @media screen and (max-width: $xs-device) {
        text-align: justify;
        text-justify: inter-word;
    }
} */
  