$primary-light-blue: #8DB9ED;
$primary-line-color: #ccc;

$primary-color: #ca052e;
$secondary-color: #00256f;
$white-color: #ffffff;

$xs-device : 600px; // max-width
$sm-device : 600px; // min-width
$md-device : 768px; // min-width
$lg-device : 992px; // min-width
$xlg-device : 1200px; // min-width


.footer-main {
	font: 11px "Open Sans", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin: auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between
}

ul {
  	list-style: none
}

a {
  	text-decoration: none
}

.generic-anchor {
	color: $primary-light-blue;
	&:visited {
		color: $primary-light-blue
	}
	&:hover {
		color: $primary-line-color
	}
}
.flex-rw {
  display: flex;
  flex-flow: row wrap;
}

footer {
  background: linear-gradient(rgba(0, 0, 0, 0.8) 4%, #002b80 30%);
  margin-top: auto;
  width: 100%;
}

.footer-list-top {
  width: 25%
}

.footer-list-top > li {
  text-align: left;
  padding-bottom: 10px;
}

.footer-list-header {
  padding: 10px 0 5px 0;
  color: #fff;
  font: 1.8vw "Oswald", sans-serif
}

.footer-list-anchor {
  font: 1.3em "Open Sans", sans-serif
}

.footer-social-section {
  width: 100%;
  align-items: center;
  justify-content: space-around;
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;
}
.footer-social-section::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 10px;
  border-top: 1px solid $primary-line-color;
  width: calc(100% - 20px)
}
.footer-social-overlap {
  position: relative;
  z-index: 2;
  background: #002b80;
  padding: 0 20px
}
.footer-social-connect {
  display: flex;
  align-items: center;
  font: 3em "Oswald", sans-serif;
  color: #fff;
}
.footer-social-small {
  font-size: 0.6em;
  padding: 0px 20px
}
.footer-social-overlap > a {
  font-size: 3em
}
.footer-social-overlap > a:not(:first-child) {
  margin-left: 0.80em
}
.footer-bottom-section {
  width: 100%;
  padding: 17px 0 17px 10px;
  border-top: 2px solid #ca052e;
  margin-top: 10px
}
.footer-bottom-section > div:first-child {
  margin-right: auto;
}
.footer-bottom-wrapper {
  font-size: 1.2em;
  color: #fff;
}
.footer-address {
  display: inline;
  font-style: normal
}
@media only screen and (max-width: 768px) {
  .footer-list-header {
    font-size: 2em
  }
  .footer-list-anchor {
    font-size: 1.1em
  }
  .footer-social-connect {
    font-size: 2.5em
  }
  .footer-social-overlap > a {
    font-size: 2.24em
  }
  .footer-bottom-wrapper {
    font-size: 1.3em
  }
}
@media only screen and (max-width: 568px) {
  main {
    font-size: 5em
  }
  .footer-list-top {
    width: 100%
  }
  .footer-list-header {
    font-size: 2em;
  }
  .footer-list-anchor {
    font-size: 1.2em
  }
  .footer-social-section {
    justify-content: center
  }
  .footer-social-section::after {
    top: 25%
  }
  .footer-social-connect {
    margin-bottom: 10px;
    padding: 0 10px
  }
  .footer-social-overlap {
    display: flex;
    justify-content: center;
  }
  .footer-social-icons-wrapper {
    width: 100%;
    padding: 0
  }
  .footer-social-overlap > a:not(:first-child) {
    margin-left: 20px;
  }
  .footer-bottom-section {
    padding: 0 5px 10px 5px
  }
  .footer-bottom-wrapper {
    text-align: center;
    width: 100%;
    margin-top: 10px
  }
}
@media only screen and (max-width: 480px) {
  .footer-social-overlap > a {
    margin: auto
  }
  .footer-social-overlap > a:not(:first-child) {
    margin-left: 0;
  }
  .footer-bottom-rights {
    display: block
  }
}
@media only screen and (max-width: 320px) {
  .footer-list-header {
    font-size: 2.2em
  }
  .footer-list-anchor {
    font-size: 1.2em
  }
  .footer-social-connect {
    font-size: 2.4em
  }
  .footer-social-overlap > a {
    font-size: 2.24em
  }
  .footer-bottom-wrapper {
    font-size: 1.3em
  }
}

.main-address {
  background-color: $primary-color;
  color : $white-color;

  .reach-heading {
    padding: 2% 0 2% 0;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
  }

  @media screen and (max-width: $xs-device) {
    .reach-heading {
      padding: 5% 0 2% 0;
      text-align: center;
      font-size: 18px;
      font-weight: 700;
    }
  }

  .address-flex {
    display: flex;
    text-align: center;
    padding-bottom: 2%;
    font-weight: 300;

    .usa-address {
      width: 50%;
      font-weight: 400;
    }

    .india-address {
      width: 50%;
      font-weight: 400;
    }
  }

  @media screen and (max-width: $xs-device) {
    .address-flex {
      display: inline;
      text-align: left;
    } 

    .usa-address {
      width: 100%;
      padding: 5% 0 8% 9%;
    }

    .india-address {
      width: 100%;
      padding: 5% 0 8% 9%;
    }
  }

}