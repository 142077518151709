$xs-device : 600px; // max-width
$sm-device : 600px; // min-width
$md-device : 768px; // min-width
$lg-device : 992px; // min-width
$xlg-device : 1200px; // min-width

$primary-color: #ca052e;
$secondary-color: #00256f;
$white-color: #ffffff;

.careers-content {
    padding: 5%;
    text-align: center;
    background-color: white;
    font-weight: 500;
    color: #5E4F45;
    font-size: 16px;

    @media screen and (max-width: $xs-device) {
        text-align: justify;
        font-size: 13px;
        padding: 25px 10px 25px 10px;
    }
}

.open-positions-main {
    padding: 4% 0 2% 0;
    text-align: center;

    .open-title {
        font-size: 32px;
        color: $primary-color;
        font-weight: 700;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

        @media screen and (max-width: $xs-device) {
            font-size: 26px;
        }
    }

    .position-title {
        font-size: 32px;
        color: $secondary-color;
        font-weight: 500;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

        @media screen and (max-width: $xs-device) {
            font-size: 26px;
        }
    }
}


.custom-buttons {
    text-align: center;
    margin: auto;
    display: block;
    padding-bottom: 3%;
    padding-top: 5%;

    button {
        background-color: transparent;
        background-repeat: no-repeat;
        border: 1px solid rgba(111, 0, 255, 0.609);
        overflow: hidden;
        padding: 9px 30px;
        text-align: center;
        font-size: 13px;
        margin: 0 2px;
    }

    .button1 {
        color: black; 
        font-weight: 500;
        border-radius: 35px 0 0 35px;
        outline: none;
    }

    .button2 {
        color: black; 
        font-weight: 500;
        border-radius: 0 35px 35px 0;
        outline: none;
    }

    .button1:hover {
        background-color: #81cfe4;
    }

    .button2:hover {
        background-color: #81cfe4;
    }
}


.custom-table {
    padding-bottom: 5%;
    .cs-table {
        width: 100%;
        border-collapse:separate; 
        border-spacing:0 15px; 

        thead > tr > th  {
            padding: 0 0 10px 10px;
            font-family: sans-serif;
            color: black;
        }

        tbody > tr > td {
            padding: 15px 0 15px 10px;
            font-weight: 500;
            color: black;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        }
        
        tbody > tr:nth-child(odd) {
            background: #e8e8e8;
        }

        tbody > tr:nth-child(even) {
            background: #d9d9d9;
        }

        tbody > tr:hover {
            background-color: white;
            position: relative;
        }

        tbody a {
            font-size: 18px;
            font-weight: 500;
            color: $primary-color;
        }

        tbody a:hover {
            color: $secondary-color;
        }
    }
}

.job-main-content {
    margin: 5% 15% 10% 15%;

    .hr-line {
        height: 1px;
        background-color: $secondary-color;
        margin-top: 3%;
        margin-bottom: 3%;
    }

    .job-position-main {

        .position-title {
            font-size: 35px;
            text-transform: capitalize;
            font-weight: 500;
            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
            color: black;
        }

        .city {
            font-size: 13px;
            font-weight: 500;
            color: black;
        }

        .country {
            font-size: 13px;
            text-transform: uppercase;
            font-weight: 500;
            color: black;
        }

        .responsibilites {
            padding-top: 3%;
            
            .responsibility-heading {
                text-transform: uppercase;
                color: $primary-color;
                font-size: 18px;
                font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                font-weight: 600;
            }

            .responsibility-content {
                color: black;
                font-size: 13px;
                padding-top: 1%;
                font-weight: 500;
            }
        }

        .experiance {
            padding-top: 3%;
            
            .experiance-heading {
                text-transform: uppercase;
                color: $primary-color;
                font-size: 18px;
                font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                font-weight: 600;
            }

            .experiance-content {
                color: black;
                font-size: 13px;
                padding-top: 1%;
                font-weight: 500;
            }
        }

        .environment {
            padding-top: 3%;
            
            .environment-heading {
                text-transform: uppercase;
                color: $primary-color;
                font-size: 18px;
                font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                font-weight: 600;
            }

            .environment-content {
                p {
                    color: black;
                    font-size: 13px;
                    padding-top: 1%;
                    font-weight: 500;
                }
            }
        }

        .about-us {
            padding-top: 3%;
            
            .about-us-heading {
                text-transform: uppercase;
                color: $primary-color;
                font-size: 18px;
                font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                font-weight: 600;
            }

            .about-us-content {
                color: black;
                font-size: 13px;
                padding-top: 1%;
                font-weight: 500;
            }
        }
    }
}

.job-apply-heading {
    font-size: 35px;
    text-transform: capitalize;
    font-weight: 500;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: $primary-color;
}

.form-main {
    input {
        width: 100%;
        padding: 8px 0 8px 0;
        border: 0.5px solid black;
        background: transparent;
        display: list-item;
        outline: none;
        text-indent: 17px;
        color: #212529;
        font-weight: 400;
    }

    input[type=file] {
        border: none;
        padding: 0;
        text-indent: 0;
    }

    select {
        width: 100%;
        padding: 8px 0 8px 0;
        border: 0.5px solid black;
        background: transparent;
        display: list-item;
        outline: none;
        text-indent: 17px;
        color: #212529;
        font-weight: 400;
    }

    ::placeholder {
        color: #212529;
        font-size: 12px;
    }

    .labelStyle {
        font-weight: 500;
        color: black;
    }

    .validationMsg {
        font-size: 12px;
        color: red;
        font-weight: 500;
        padding-top: 7px;
    }
}

.submitButton {
    margin-top: 62px;

    button {
        background-color: $secondary-color;
        border: none;
        color: white;
        padding: 8px 26px;
        text-align: center;
        text-decoration: none;
        font-size: 14px;
        cursor: pointer;
        float: right;
        font-weight: 400;
        border-radius: 3px;
    }
    
    button:hover {
        background-color: $primary-color;
    }
}

