$xs-device : 600px; // max-width
$sm-device : 600px; // min-width
$md-device : 768px; // min-width
$lg-device : 992px; // min-width
$xlg-device : 1200px; // min-width

$primary-color: #ca052e;
$secondary-color: #00256f;
$white-color: #ffffff;

.main-timeline {
    margin-top: 8%;
    margin-bottom: 6%;
}


.our-locations {
    text-align: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    background-color: $white-color;
    padding-top: 3%;
    padding-bottom: 5%;

    .our {
        font-size: 34px;
        color: $primary-color;
        font-weight: 700;
    }

    .locations {
        font-size: 34px;
        color: $secondary-color;
        font-weight: 500;
    }

    .our-locations-images {
        position: relative;
        padding-top: 5%;

        .first-location {
            clip-path: polygon(100% 0, 0 100%, 100% 100%, 75% 100%, 0 100%, 0 0, 53% 0);
            height: 350px;
            width: 100%;
            background-image: url("../../assets/img/customImages/USAONE.png");
            background-position: left top;
            position: absolute;

            @media screen and (max-width: 600px) {
                background-position: right;
            }

            .first-text {
                color: black;
                font-size: 40px;
                position: absolute;
                top: 20%;
                left: 10%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                text-align: center;
                font-weight: 500;

                @media screen and (min-width: $md-device) and (max-width: $lg-device) {
                    font-size: 30px;
                }

                @media screen and (max-width: 600px) {
                    font-size: 30px;
                    left: 20%;
                    top: 20%;
                }
            }
        }

        .first-location:hover {
            opacity: 0.8;
        }

        .second-location {
            clip-path: polygon(100% 0, 100% 100%, 75% 100%, 0 100%, 0 100%);
            height: 350px;
            width: 100%;
            background-image: url("../../assets/img/customImages/INDIAONE.png");
            background-position: left top;
            
            @media screen and (min-width: $md-device) and (max-width: $lg-device) {
                background-position: center top;
            }

            @media screen and (max-width: 600px) {
                background-position: right;
                opacity: 0.7;
            }

            .second-text {
                color: black;
                font-size: 40px;
                position: absolute;
                top: 80%;
                left: 90%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                text-align: center;
                font-weight: 500;

                @media screen and (min-width: $md-device) and (max-width: $lg-device) {
                    font-size: 30px;
                }

                @media screen and (max-width: 600px) {
                    font-size: 30px;
                    left: 70%;
                    top: 80%;
                }
            }
        }

        .second-location:hover {
            opacity: 0.7;
        }

        

    }
    
}