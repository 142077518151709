$xs-device : 600px; // max-width
$sm-device : 600px; // min-width
$md-device : 768px; // min-width
$lg-device : 992px; // min-width
$xlg-device : 1200px; // min-width

$primary-color: #ca052e;
$secondary-color: #00256f;
$white-color: #ffffff;

body { 
    background-color: #F7F2F0;
}

.main-what-we-do-page-video {
    @media screen and (min-width: $xlg-device) {
        display: initial;
    }

    @media screen and (max-width: $xlg-device) {
        display: none;
    }
} 

.ipad-what-we-do-page-video {

    @media screen and (max-width: $sm-device) {
        display: none;
    }

    @media screen and (min-width: $xlg-device) {
        display: none;
    }
}

.phone-what-we-do-page-video {

    @media screen and (min-width: $md-device) {
        display: none;
    }
}

.main-what-we-do-content {
    padding-top: 5%;
    padding-bottom: 5%;

    .expertise-heading {
        text-align: center;
        font-size: 30px;
        color: $primary-color;
        font-weight: 500;
        padding-bottom: 5%;

        @media screen and (max-width: $xs-device) {
            font-size: 26px;
        }

        .expertise-description {
            text-align: center;
            font-weight: 500;
            color: #5E4F45;
            font-size: 16px;
            padding-top: 2%;

            @media screen and (max-width: $xs-device) {
                text-align: justify;
                font-size: 13px;
                padding: 10px 10px 0 10px;
            }
        }
    }

    .services-main-heading {
        text-align: center;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        padding-bottom: 5%;

        .who-title {
            font-size: 34px;
            color: $primary-color;
            font-weight: 700;
            
            @media screen and (max-width: $xs-device) {
                font-size: 26px;
            }
        }

        .we-are-title {
            font-size: 34px;
            color: $secondary-color;
            font-weight: 500;

            @media screen and (max-width: $xs-device) {
                font-size: 26px;
            }
        }
    }

    .main-heading {
        text-align: left;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
    }

    .service-heading {
        font-size: 25px;
        color: $primary-color;
        font-weight: 500;

        @media screen and (max-width: $xs-device) {
            font-size: 18px;
            text-align: justify;
            padding: 10px 10px 0 10px;
        }
    }

    .heading-content {
        text-align: left;
        font-weight: 500;
        color: #5E4F45;
        font-size: 14px;

        @media screen and (max-width: $xs-device) {
            font-size: 13px;
            text-align: justify;
            padding: 10px 10px 0 10px;
            
        }
    }

    .description-content {
        font-weight: 500;

        @media screen and (max-width: $xs-device) {
            text-align: justify;
            padding: 10px 10px 0 10px;
        }
    }

    .image-background {
        img {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }

        @media screen and (max-width: $xs-device) {
            display: none;
        }
    }
}

.button-one {
    @media screen and (max-width: $xs-device) {
        padding: 10px 10px 0 10px;
    }
}

.mobile-pics {
    @media screen and (max-width: $xs-device) {
        padding: 10px 10px 10px 10px;
    }

    @media screen and (min-width: $xs-device) {
        display: none;
    }
}


.model-main-heading {
    padding-top: 3%;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: center;
    background-color: $white-color;
    overflow: hidden;

    .engagement-title {
        font-size: 34px;
        color: $primary-color;
        font-weight: 700;

        @media screen and (max-width: $xs-device) {
            font-size: 26px;
        }
    }

    .model-title {
        font-size: 34px;
        color: $secondary-color;
        font-weight: 500;

        @media screen and (max-width: $xs-device) {
            font-size: 26px;
        }
    }

    .model-heading {
        padding-top: 5%;
        font-size: 20px;
        font-weight: 400;
        color: $primary-color;
        font-family: Verdana, Geneva, Tahoma, sans-serif;

        @media screen and (max-width: $xs-device) {
            font-size: 18px;
        }
    }

    p {
        padding-top: 5%;
        text-align: justify;
        font-weight: 400;
        padding: 40px;

        @media screen and (max-width: $xs-device) {
            padding-top: 4%;
        }
    }

}


