$xs-device : 600px; // max-width
$sm-device : 600px; // min-width
$md-device : 768px; // min-width
$lg-device : 992px; // min-width
$xlg-device : 1200px; // min-width

$primary-color: #ca052e;
$secondary-color: #00256f;
$white-color: #ffffff;

body { 
    background-color: #F7F2F0;
}

.our-main-presence {
    text-align: center;
    margin-top: 5%;
    margin-bottom: 5%;
    overflow: hidden;
    padding-left: 2%;
    padding-right: 2%;

    .our-title {
        font-size: 34px;
        color: $primary-color;
        font-weight: 700;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

        @media screen and (max-width: $xs-device) {
            font-size: 28px;
        }
    }

    .presence-title {
        font-size: 34px;
        color: $secondary-color;
        font-weight: 500;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

        @media screen and (max-width: $xs-device) {
            font-size: 28px;
        }
    }

    .cards {
        margin-top: 7%;
    }

    .first-card {
        height: 40vh;
        width: 80%;
        background-image: url("../../assets/img/compressedImages/compressed.jpg");
        margin-left: auto;
        margin-right: auto;
        transition: all 0.3s ease;
        transform: scale(1);
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 15px;
        box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
        opacity: 0.9;

        @media screen and (min-width: 600px) and (max-width: 992px) {
            background-position: center center;
        }
    }

    .first-card:hover {
        transform: scale(1.1) perspective(1px)
    }

    .second-card {
        height: 40vh;
        width: 80%;
        background-image: url("../../assets/img/compressedImages/insurance2.jpg");
        margin-left: auto;
        margin-right: auto;
        transition: all 0.3s ease;
        transform: scale(1);
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 15px;
        box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
        opacity: 0.8;

        @media screen and (max-width: $xs-device) {
            margin-top: 5%;
        }

        @media screen and (min-width: 600px) and (max-width: 992px) {
            background-position: center center;
        }
    }

    .second-card:hover {
        transform: scale(1.1) perspective(1px)
    }

    .third-card {
        height: 40vh;
        width: 80%;
        background-image: url("../../assets/img/compressedImages/retail2.jpg");
        margin-left: auto;
        margin-right: auto;
        transition: all 0.3s ease;
        transform: scale(1);
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 15px;
        box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
        opacity: 0.8;

        @media screen and (min-width: 600px) and (max-width: 992px) {
            background-position: center center;
        }
    }

    .third-card:hover {
        transform: scale(1.1) perspective(1px)
    }

    .fourth-card {
        height: 40vh;
        width: 80%;
        background-image: url("../../assets/img/compressedImages/Media-min.jpg");
        margin-left: auto;
        margin-right: auto;
        transition: all 0.3s ease;
        transform: scale(1);
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 15px;
        box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
        opacity: 0.9;

        @media screen and (max-width: $xs-device) {
            margin-top: 5%;
        }

        @media screen and (min-width: 600px) and (max-width: 992px) {
            background-position: center center;
        }
    }

    .fourth-card:hover {
        transform: scale(1.1) perspective(1px)
    }

    .fifth-card {
        height: 40vh;
        width: 80%;
        background-image: url("../../assets/img/compressedImages/internetServices-min.jpg");
        margin-left: auto;
        margin-right: auto;
        transition: all 0.3s ease;
        transform: scale(1);
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 15px;
        box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
    }

    .fifth-card:hover {
        transform: scale(1.1) perspective(1px)
    }

    .sixth-card {
        height: 40vh;
        width: 80%;
        background-image: url("../../assets/img/compressedImages/logistics.jpg");
        margin-left: auto;
        margin-right: auto;
        transition: all 0.3s ease;
        transform: scale(1);
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 15px;
        box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);

        @media screen and (max-width: $xs-device) {
            margin-top: 5%;
        }
    }

    .sixth-card:hover {
        transform: scale(1.1) perspective(1px)
    }

    .seventh-card {
        height: 40vh;
        width: 80%;
        background-image: url("../../assets/img/compressedImages/engineeringServices-min.jpg");
        margin-left: auto;
        margin-right: auto;
        transition: all 0.3s ease;
        transform: scale(1);
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 15px;
        box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
    }

    .seventh-card:hover {
        transform: scale(1.1) perspective(1px)
    }

    .eight-card {
        height: 40vh;
        width: 80%;
        background-image: url("../../assets/img/compressedImages/Hitech.jpg");
        margin-left: auto;
        margin-right: auto;
        transition: all 0.3s ease;
        transform: scale(1);
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 15px;
        box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);

        @media screen and (max-width: $xs-device) {
            margin-top: 5%;
        }

        @media screen and (min-width: 600px) and (max-width: 992px) {
            background-position: center center;
        }
    }

    .eight-card:hover {
        transform: scale(1.1) perspective(1px)
    }

    .ninth-card {
        height: 40vh;
        width: 80%;
        background-image: url("../../assets/img/compressedImages/travel-min.jpg");
        margin-left: auto;
        margin-right: auto;
        transition: all 0.3s ease;
        transform: scale(1);
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 15px;
        box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
    }

    .ninth-card:hover {
        transform: scale(1.1) perspective(1px)
    }

    .card-content {
        color: white;
        font-size: 23px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
        font-weight: 500;
    }
}